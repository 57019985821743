import { ROOT_URL } from '../config';

export const placeOrder = async (shippingInfo) => {
  const res = await fetch(`${ROOT_URL}/order`, {
    headers: { "Content-Type": "application/json" },
    method: 'POST',
    body: JSON.stringify(shippingInfo),
    credentials: 'include',
  }).then((res) => res.json());
  return res.data;
};

export const getRecentOrders = async () => {
  const res = await fetch(`${ROOT_URL}/order`, {
    credentials: 'include',
  }).then((res) => res.json());
  return res.data;
}

export const getOrderDetail = async (id) => {
  const res = await fetch(`${ROOT_URL}/order/${id}`, {
    credentials: 'include',
  }).then((res) => res.json());
  return res.data;
}