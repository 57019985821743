import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as orderApi from '../../api/order';
import './index.css';
import { IMG_DOMAIN } from '../../config';
import RatingStar from '../../components/RatingStar/RatingStar';
import * as productApi from '../../api/product';

export default function OrderPage() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);

  useEffect(() => {
    orderApi
      .getRecentOrders()
      .then((res) => {
        console.log('res: ', res);
        setOrders(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const goToDetail = (orderId) => {
    navigate(`${orderId}`);
  };

  const rateProduct = (phoneId, rating) => {
    console.log('phoneId, rating: ', phoneId, rating);
    productApi
      .rateProduct(phoneId, rating)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const res = {};
    const allProducts = orders.reduce((acc, cur) => {
      return [...acc, ...cur.products.map((p) => p.phone)];
    }, []);

    for (
      let i = 0;
      i < allProducts.length && Object.keys(res).length < 5;
      i++
    ) {
      const phone = allProducts[i];
      if (!res[phone._id]) {
        res[phone._id] = phone;
      }
    }

    setRecentProducts(Object.values(res));
  }, [orders]);

  return (
    <div className="order-page">
      <h1>Last 5 Products</h1>

      <div className="last-5-products">
        {recentProducts.map((phone) => (
          <div key={phone._id} className="order-item-product">
            <img src={`${IMG_DOMAIN}/${phone.imgs[0]}`} alt="" />
            <span>{phone.title}</span>
            <RatingStar onRate={(...args) => rateProduct(phone.id, ...args)} />
          </div>
        ))}
      </div>

      <h1>Order Page</h1>

      {orders.map((order) => (
        <div
          className="order-item"
          key={order._id}
          onClick={() => goToDetail(order._id)}
        >
          {order.products.map((product) => (
            <div key={product.phone._id} className="order-item-product">
              <img src={`${IMG_DOMAIN}/${product.phone.imgs[0]}`} alt="" />
              <span>{product.phone.title}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
