import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IMG_DOMAIN } from '../../config';
import './Product.css';

export default function Product({
  id,
  imgs,
  title,
  brand,
  price,
  score,
  reviewCnt,
}) {
  const navigate = useNavigate();

  const goToDetail = () => {
    navigate(`/details/${id}`);
  };

  return (
    <div className="product" onClick={goToDetail}>
      <img className="image" src={`${IMG_DOMAIN}/${imgs[0]}`} alt="" />
      <div className="title">{title}</div>
      <div className="brand">{brand}</div>
      <div className="price">${price}</div>
      <div className="reviews">
        {renderStars(score)} {reviewCnt}
      </div>
    </div>
  );
}

function renderStars(score) {
  const solid = Math.floor(score);
  return (
    new Array(solid).fill('★').join('') +
    new Array(5 - solid).fill('☆').join('')
  );
}
