import React, { useState } from 'react';
import './RatingStar.css';

export default function RatingStar({ onRate }) {
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const handleRatingChange = (value) => {
    if (submitted) return;
    setRating(value);
  }

  const handleSubmit = () => {
    setSubmitted(true);
    onRate(rating);
  }

  return (
    <div className='rating-star'>
      {new Array(5).fill().map((_, i) => (
        <span
          key={i}
          className={`${rating > i ? 'filled' : ''}`}
          onMouseEnter={() => handleRatingChange(i + 1)}
          onMouseLeave={() => handleRatingChange(0)}
          onClick={() => handleSubmit()}
        />
      ))}
    </div>
  );
}
