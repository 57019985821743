import React, { useEffect, useState } from 'react';
import Product from '../../components/Product/Product';
import * as productApi from '../../api/product';
import './index.css';

export default function ProductsPage() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    productApi
      .getProductList()
      .then((products) => {
        console.log('products: ', products);
        setProducts(products)
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="product-page">
      <div className="products-container">
        {products.map((product) => (
          <Product key={product.id} {...product} />
        ))}
      </div>
    </div>
  );
}
