import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import * as orderApi from '../../api/order';
import { IMG_DOMAIN } from '../../config';
import './detail.css';

const shippingMethodMap = {
  1: '6-days ground',
  2: '2-days expedited',
  3: 'Overnight',
};

export default function OrderDetailPage() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    orderApi
      .getOrderDetail(id)
      .then((res) => {
        console.log(res);
        setOrder(res);
      })
      .catch((err) => console.log(err));
  }, [id]);

  if (!order) return null;

  const { name, email, phone, address, method } = order.shipping;

  return (
    <div className="order-detail-page">
      <h1>Order Details</h1>

      {/* Products */}
      {order.products.map((product, i) => (
        <div key={i} className="cart-item">
          <div className="cart-item-product">
            <img src={`${IMG_DOMAIN}/${product.phone.imgs[0]}`} alt="" />
            <span>{product.phone.title}</span>
          </div>
          <span className="cart-item-quantity">x {product.quantity}</span>
        </div>
      ))}

      {/* Time */}
      <div className="order-detail-info">
        <div>
          <b>Ordered Time</b>:{' '}
          {dayjs(order.createdAt).format('YYYY/MM/DD HH:mm')}
        </div>
        <div>
          <b>Subtotal</b>: ${order.subtotal.toFixed(2)}
        </div>
      </div>

      {/* Shipping */}
      <div className="order-detail-shipping">
        <h3>Shipping Information</h3>
        <div>
          <b>Name</b>: {name}
        </div>
        <div>
          <b>Email</b>: {email}
        </div>
        <div>
          <b>Phone</b>: {phone}
        </div>
        <div>
          <b>Address</b>: {address}
        </div>
        <div>
          <b>Shipping Method</b>: {shippingMethodMap[method]}
        </div>
      </div>
    </div>
  );
}
