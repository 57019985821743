import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as productApi from '../../api/product';
import * as cartApi from '../../api/cart';
import './index.css';
import { IMG_DOMAIN } from '../../config';

export default function DetailsPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    productApi
      .getProductDetail(id)
      .then((product) => {
        console.log('product: ', product);
        setProduct(product);
      })
      .catch((err) => console.log(err));
  }, [id]);

  if (!product) return null;

  const addToCart = () => {
    cartApi
      .addToShoppingCart(id)
      .then((res) => {
        console.log(res);
        alert('Added to shopping cart successfully!')
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="detail-page">
      <h1 className="title">{product.title}</h1>
      <div className="brand">{product.brand}</div>
      <div className="desc">{product.description}</div>
      <div className="price">${product.price}</div>

      <div className="orderbtn" onClick={addToCart}>
        Add To Cart
      </div>

      <div className="images">
        {product.imgs.map((image, i) => (
          <img key={i} src={`${IMG_DOMAIN}/${image}`} alt="" />
        ))}
      </div>
    </div>
  );
}
