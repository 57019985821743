import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import ProductsPage from './pages/products';
import DetailsPage from './pages/details';
import AboutPage from './pages/about';
import CartPage from './pages/cart';
import OrderPage from './pages/order';
import OrderDetailPage from './pages/order/detail';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <ProductsPage />,
      },
      {
        path: '/details/:id',
        element: <DetailsPage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/cart',
        element: <CartPage />,
      },
      {
        path: '/order',
        element: <OrderPage />,
      },
      {
        path: '/order/:id',
        element: <OrderDetailPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
