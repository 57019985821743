import { ROOT_URL } from '../config';

export const addToShoppingCart = async (phoneId) => {
  const res = await fetch(`${ROOT_URL}/cart`, {
    headers: { "Content-Type": "application/json" },
    method: 'POST',
    body: JSON.stringify({ phoneId }),
    credentials: 'include',
  }).then((res) => res.json());
  return res.data;
};

export const getShoppingCart = async () => {
  const res = await fetch(`${ROOT_URL}/cart`, {
    credentials: 'include',
  }).then((res) => res.json());
  return res.data;
};
