import React, { useState } from 'react';
import './OrderForm.css';

export default function OrderForm({ visible, onClose, onSubmit }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [method, setMethod] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    onSubmit({
      name: `${firstName} ${lastName}`,
      email,
      phone,
      address,
      method,
    });
  }

  function validateFields() {
    if (!firstName || !lastName || !phone || !address || !method) {
      alert('Missing information!');
      return false;
    }
    // validate phone
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(!phone.match(phoneno)) {
      alert("Phone invalid!");
      return false;
    }
    // validate address
    if (!/^[\w\s ,.]+$/.test(address)) {
      alert("Address invalid!");
      return false
    }
    return true;
  }

  if (!visible) return null;

  return (
    <div className="orderform">
      <div className="modal-overlay" onClick={onClose} />
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <h2>Order Information</h2>

          {/* First Name */}
          <div className="form-item">
            <label htmlFor="fname">First Name</label>
            <input
              id="fname"
              required
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          {/* Last Name */}
          <div className="form-item">
            <label htmlFor="lname">Last Name</label>
            <input
              id="lname"
              required
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          {/* Phone */}
          <div className="form-item">
            <label htmlFor="phone">Phone</label>
            <input
              id="phone"
              required
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          {/* Email */}
          <div className="form-item">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              required
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* Shipping Address */}
          <div className="form-item">
            <label htmlFor="address">Shipping Address</label>
            <textarea
              id="address"
              required
              rows="5"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          {/* Shipping Method */}
          <div className="form-item">
            <label htmlFor="shipping">Shipping Method</label>
            <select
              id="shipping"
              required
              value={method}
              onChange={(e) => setMethod(e.target.value)}
            >
              <option value="1">6-days ground</option>
              <option value="2">2-days expedited</option>
              <option value="3">Overnight</option>
            </select>
          </div>

          <input className='submit-btn' type="submit" value="Place Order" />
        </form>
      </div>
    </div>
  );
}
