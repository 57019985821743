import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderForm from '../../components/OrderForm/OrderForm';
import * as cartApi from '../../api/cart';
import * as orderApi from '../../api/order';

import './index.css';
import { IMG_DOMAIN } from '../../config';

export default function CartPage() {
  const navigate = useNavigate();
  const [shoppingCart, setShoppingCart] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [showForm, setShowForm] = useState(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  useEffect(() => {
    cartApi
      .getShoppingCart()
      .then((res) => {
        console.log(res);
        setShoppingCart(res.items);
        setSubtotal(res.subtotal);
      })
      .catch((err) => console.error(err));
  }, []);

  const placeOrder = ({ name, email, phone, address, method }) => {
    orderApi
      .placeOrder({
        name,
        email,
        phone,
        address,
        method,
      })
      .then((res) => {
        console.log(res);
        alert('Order placed successfully!');
        handleCloseForm();
        navigate(`/order/${res._id}`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="cart-page">
      <h1>Shopping Cart</h1>

      {shoppingCart.map((cartItem, i) => (
        <div key={i} className="cart-item">
          <div className='cart-item-product'>
            <img src={`${IMG_DOMAIN}/${cartItem.phone.imgs[0]}`} alt="" />
            <span>{cartItem.phone.title}</span>
          </div>
          <span className='cart-item-quantity'>x {cartItem.quantity}</span>
        </div>
      ))}

      <div className="cart-subtotal">Subtotal: ${subtotal.toFixed(2)}</div>

      <div className="orderbtn" onClick={handleOpenForm}>
        Checkout
      </div>

      <OrderForm
        visible={showForm}
        onClose={handleCloseForm}
        onSubmit={placeOrder}
      />
    </div>
  );
}
