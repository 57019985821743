import { ROOT_URL } from '../config';

export const getProductList = async () => {
  const res = await fetch(`${ROOT_URL}/product`).then((res) => res.json());
  return res.data;
};

export const getProductDetail = async (id) => {
  const res = await fetch(`${ROOT_URL}/product/${id}`).then((res) =>
    res.json()
  );
  return res.data;
};

export const rateProduct = async (phoneId, rating) => {
  const res = await fetch(`${ROOT_URL}/product/${phoneId}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      rating,
    }),
    credentials: 'include',
  }).then((res) => res.json());
  return res.data;
};
