import React from 'react';
import { Link } from 'react-router-dom';
import './Link.css';

export default function CustomLink({ to, children, ...props }) {
  return (
    <Link className="link" to={to} {...props}>
      {children}
    </Link>
  );
}
