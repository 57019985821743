import React from 'react';
import Link from '../Link/Link';
import './Navbar.css';

export default function Navbar() {
  return (
    <div className="navbar">
      <div className="left">
        <Link to={'/'}>My Ecommerce</Link>
      </div>

      <div className="right">
        <Link to={'/about'}>About Us</Link>
        <Link to={'/cart'}>Shopping Cart</Link>
        <Link to={'/order'}>Orders</Link>
      </div>
    </div>
  );
}
