import React from 'react'
import './index.css'

export default function AboutPage() {
  return (
    <div className="about-page">
      <h1>About Us</h1>
      <p>Steve-Jeremy's Store: The best way to buy the products you love. Help is here. Whenever and however you need it. We only sell phones in a variety of brands with quality, value, and innovation. Besides, we will understand and serve the customer better than anyone else, forget about everything else, and make sure every little thing you do serves, always and everywhere.</p>

      <h2>Team Member</h2>
      <p>Jiang Jingyuan</p>
      <p>Chenxu Fu</p>
    </div>
  )
}
